import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useBreakpoint } from "gatsby-plugin-breakpoints"


const Products = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const breakPoints = useBreakpoint();
  let position

  if(breakPoints.md) {
    position = ( {position: "absolute"} )
  }else{
    position = ( {position: "relative"} )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="自社開発（プロダクト）｜WEBサイト監視の「モニタリングプラス」・看護師のためのアプリ「NuRseCall」｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-productPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">Products</h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <AnchorLink to="#MonitoringPlus" className=''>
                モニタリングプラス
              </AnchorLink>
            </li>
            <li className='mb-5'>
              <AnchorLink to="#Nursecall" className=''>
                NuRseCall
              </AnchorLink>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

          <section id="PageDescription" className="relative w-full mb-20">
            <p className="py-16 pl-10 pr-10 md:pr-32 text-xl sm:text-2xl w-full md:w-2/3 bg-key-sand">
              スタートアッププラスは常に新しい価値とサービスを提供し続けます<br />
              自社単独での開発だけでなく、他社とのパートナーシップや共同開発など様々な形でサービスを世に送り出しています
            </p>
            <StaticImage src="../images/products-top.png" 
              className="-bottom-8 right-0 w-full md:w-[350px] "
              style={position} />
          </section>

          <hr className=" border-key-sand my-20 "></hr>

          <section id="MonitoringPlus">
            <StaticImage src="../images/monipuraLogo.png" className="w-[350px] mb-5"/>
            <StaticImage src="../images/monipuraImage.png" className="w-full"/>
            <div className="border-l-8 border-key-sand mt-10 mb-5 py-2 pl-3">
              <h2 className="text-2xl">モニタリングプラス</h2>
              <p className="mt-2">気軽にウェブサイト監視をはじめられるウェブサービス</p>
            </div>
            <p className="sm:text-lg">
            ウェブサイトの表示(http/https)、ドメイン名の利用期限、SSL証明書の利用期限、を定期的にモニタリングするウェブサービスです。
            対象ウェブサイトのURLをご登録いただくだけで、どなたでも簡単に無料でウェブサイト監視をはじめることが出来ます。<br />
            また、有料プランにご登録いただくことでシナリオ監視、最大世界5カ所からの監視、監視対象サイトの追加など、より拡充されたサービスをご利用いただくことが出来ます。
            </p>

            <div className="flex justify-end">
              <Link to="https://www.monitoring-plus.jp/" target="_blank" rel="nofollow"
                className="w-auto mt-10 flex justify-center border border-neutral-800 px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
              <span className=" ">モニタリングプラスのサイトへ</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="inline ml-2 w-6 stroke-current stroke" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
              </Link>
            </div>
          </section>

          <hr className=" border-key-sand my-20 "></hr>

          <section id="Nursecall" className="mb-20">
            <StaticImage src="../images/nursecallLogo.png" className="w-[220px] mb-5"/>
            <StaticImage src="../images/nursecallImg.png" className="w-full"/>
            <div className="border-l-8 border-key-sand mt-10 mb-5 py-2 pl-3">
              <h2 className="text-2xl">NuRseCall（ナースコール）</h2>
              <p className="mt-2">心身共にストレスの多い現場で頑張る看護師さんたちの為のストレスマネジメントアプリ</p>
            </div>
            <p className="sm:text-lg">
            『NuRseCall』は看護師に特化したストレスチェックを気軽に・簡単に実施し、心と体のセルフケアをサポートするアプリです。
            また、ストレスチェックを継続的にご利用頂くために、毎日利用するシフト管理・共有ができる機能を追加しました。
            今後も、ナースに役立つ機能やサービスを展開していきます。
            </p>

            <div className="flex justify-end">
              <Link to="https://apps.nurse-support.com/" target="_blank" rel="nofollow" 
                className="w-auto mt-10 flex justify-center border border-neutral-800 px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
              <span className=" ">NuRseCallのサイトへ</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="inline ml-2 w-6 stroke-current stroke" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
              </Link>
            </div>
          </section>

          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Products

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
